import Layout from "../../components/_App/layout"
import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import {
  alternativeFormatText,
  capitalizeSentence,
  inverseCase,
  sentenceType,
  generatePascalCase, generateSnackCase, generateCamelCase, handleDownload, handleCopy
} from "../../helpers/covert-case"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const LOWERCASE = 'LOWERCASE'
const UPPERCASE = 'UPPERCASE';
const CAPITALIZED = 'CAPITALIZED';
const SENTENCE_CASE = 'SENTENCE_CASE';
const ALTERNATIVE_CASE = 'ALTERNATIVE_CASE';
const INVERSE_CASE = 'INVERSE_CASE';
const PASCAL_CASE = 'PASCAL_CASE';
const SNAKE_CASE = 'SNAKE_CASE';
const CAMEL_CASE = 'CAMEL_CASE';
const UNDO = 'UNDO';
const CLEAR = 'CLEAR';

const parent = [{
  name: "Tools",
  link: 'tools',
}];

const convert_type = [
  {
    name: SENTENCE_CASE,
    title: 'Sentence Case'
  },
  {
    name: LOWERCASE,
    title: 'Lower Case'
  },
  {
    name: UPPERCASE,
    title: 'Uppercase'
  },
  {
    name: CAPITALIZED,
    title: 'Capitalized'
  },
  {
    name: ALTERNATIVE_CASE,
    title: 'Alternative Case'
  },
  {
    name: INVERSE_CASE,
    title: 'Inverse Case'
  },
  {
    name: PASCAL_CASE,
    title: 'Pascal Case'
  },
  {
    name: SNAKE_CASE,
    title: 'Snake Case'
  },
  {
    name: CAMEL_CASE,
    title: 'Camel Case'
  },
  {
    name: UNDO,
    title: 'Undo'
  },
  {
    name: CLEAR,
    title: 'Clear'
  }
]

function ConvertCase() {

  const [selectedButton, setSelectedButton] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");
  const [text, setText] = useState('')
  const [defaultValue, setDefaultValue] = useState("")
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");

  function handleClickTextField() {
    setSelectedButton("");
  }

  function handleTextField(e) {
    setTextFieldValue(e.target.value)
    setText(e.target.value)
  }

  useEffect(() => {
    setIsInputEmpty(text === "");
  }, [text])

  function handleBlur(e) {
    setDefaultValue(e.target.value);
  }

  function handleSelect(name) {
    setSelectedButton(name)

    if(name === SENTENCE_CASE) {
      setTextFieldValue(sentenceType(text));
    }

    if(name === LOWERCASE) {
      const newText = textFieldValue.toLowerCase();
      setTextFieldValue(newText);
    }

    if(name === UPPERCASE) {
      const newText = textFieldValue.toUpperCase();
      setTextFieldValue(newText);
    }

    if(name === CAPITALIZED) {
      setTextFieldValue(capitalizeSentence(text)) ;
    }

    if(name === ALTERNATIVE_CASE) {
      setTextFieldValue(alternativeFormatText(text));
    }

    if(name === INVERSE_CASE) {
      setTextFieldValue(inverseCase(text));
    }

    if(name === PASCAL_CASE) {
      setTextFieldValue(generatePascalCase(text));
    }

    if(name === SNAKE_CASE) {
      setTextFieldValue(generateSnackCase(text));
    }

    if(name === CAMEL_CASE) {
      setTextFieldValue(generateCamelCase(text));
    }

    if(name === UNDO) {
      setTextFieldValue(defaultValue);
    }

    if(name === CLEAR) {
      setTextFieldValue("");
      setText('')
      setDefaultValue('')
    }
  }
  const buttonStyles = { backgroundColor: '#b5d6e6', color: '#1b1c1c', border: 'none', padding: '5px 10px', margin:'1.5px', borderRadius: '5px', cursor: 'pointer' };

  const handleDownloadClick = () => {
    handleDownload(textFieldValue);
    setMessage("Download completed!");
    setTimeout(() => setMessage(""), 2000);
  };

  const handleCopyClick = () => {
    handleCopy(textFieldValue);
    setMessage("Text copied to clipboard!");
    setTimeout(() => setMessage(""), 2000);
  };

  return (
    <Layout>
      <Seo title="Text Case Changer Tool"/>
      <Navbar/>
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Convert Case</h1>
            <div className="bar" />
          </div>
          <Breadcrumbs pageTitle="Convert Case" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
              <textarea name=""  value={text} onBlur={(e) => handleBlur(e)} onClick={(e) => handleClickTextField()} onChange={(e) => handleTextField(e)} className={convertStyled.text_field} id="" cols="30" rows="10"/>
              </div>
              <div className="col-lg-6">
              <textarea name=""   value={textFieldValue !== '' ? textFieldValue : text} onBlur={(e) => handleBlur(e)}  className={convertStyled.text_field} id="" cols="30" rows="10"/>
              </div>
              <div className={convertStyled.convert_list_box}>
                {
                  convert_type.map((item) => {
                    const {name, title} = item
                    const selected = selectedButton === name
                    return (
                      <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary`}  style={{ ...buttonStyles, backgroundColor: selected ? 'rgba(119,149,163,1)' : buttonStyles.backgroundColor, color: selected ? 'white' : buttonStyles.color,
                      }} key={name} onClick={() => handleSelect(name)}  disabled={isInputEmpty} >{title}
                      </button>
                    )
                  })
                }
                <div className=" py-2">
                  <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary   me-1`} onClick={handleDownloadClick} disabled={isInputEmpty} >Download</button>
                  <button className={`${convertStyled.download_copy_btn} btn text-white btn-primary `} onClick={handleCopyClick} disabled={isInputEmpty} >Copy</button>
                </div>
                {message && (<CopiedMessage message={message} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default ConvertCase
